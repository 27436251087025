<template>
  <div>
    <formTextarea :data="{type: 'text', subtype: 'text', value: getValue(), label: 'Beschreibung'}" :callback="setDescription"/>
  </div>
</template>
<script>
export default {
  components: {
    formTextarea () { return import('@/components/formBuilder/parts/textarea') }
  },
  props: {
    form: Array,
    index: Number
  },
  data () {
    return {

    }
  },
  methods: {
    getValue () {
      return JSON.parse(JSON.stringify(this.form))[this.index].description.split('<br>').join('\n')
    },
    setDescription (data) {
      this.form[this.index].description = data.split('\n').join('<br>')
    },
    setSubtype (data) {
      this.form[this.index].subtype = data
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
